import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import axios from "axios";
import {multilanguage, useRef} from "redux-multilanguage";
import ReactGA from "react-ga4";
import {Spinner} from "react-bootstrap";
import {getLabel} from "../../components/product/ShopGender";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {apiConversionRequest, GetUserData} from "../../App";
import ReactPixel from "react-facebook-pixel";
import RelatedApparelSlider from "../../wrappers/product/RelatedApparelSlider";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import parse from "react-html-parser";

const ProductTabRight = ({ location, strings, origin, props }) => {
  const { pathname } = location;
  const othersSizesRef = React.createRef();

  let slug = location.pathname.split("/product/").pop()
  const [product, setProduct] = useState(null);
  const [productRating, setProductRating] = useState(null);
  const [reference, setReference] = useState("");
  const [declination, setDeclination] = useState(null);
  const [parentCategory, setParentCategory] = useState("");
  const [gender, setGender] = useState("");
  const [dataStructured, setDataStructured] = useState(null);
  const { t, i18n } = useTranslation();
  const [article, setArticle] = useState(null);
  const [productLaunching, setProductLaunching] = useState(null);
  const [declinationLaunching, setDeclinationLaunching] = useState(null);

  const [historyPeriod, setHistoryPeriod] = useState('year');
  const [historyPrices, setHistoryPrices] = useState(null);
  const [currentTab, setCurrentTab] = useState("otherSizes");
  const [averageHistory, setAverageHistory] = useState(0);
  const [selectedUserSize, setSelectedUserSize] = useState("")
  let history = useHistory();

  const [selectedProductDeclination, setSelectedProductDeclination] = useState(
      product && product.declinations && reference ? product.declinations.filter(d => d.reference === reference) : null
  );

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });



  useEffect(() => {
      if (selectedProductDeclination && productLaunching) {
        setDeclinationLaunching(productLaunching.declinations.filter(d => d.reference === selectedProductDeclination.reference)[0])
      } else {
          /*setReference(null)
          if (product && product.bestDeclinations && product.bestDeclinations.reference !== "") {
              setCurrentDeclination(product.bestDeclinations)
          }*/
      }
  }, [selectedProductDeclination, productLaunching]);

  const setCurrentDeclination = (currentDeclination) => {
      if(currentDeclination.imgs){
        currentDeclination.imgs.unshift(currentDeclination.firstImg)
        currentDeclination.imgs = [...new Set(currentDeclination.imgs)]
    }
      setSelectedProductDeclination(currentDeclination);
  }

  useEffect(() => {
      if (selectedProductDeclination) {
          if(selectedProductDeclination.imgs){
            selectedProductDeclination.imgs.unshift(selectedProductDeclination.firstImg)
            selectedProductDeclination.imgs = [...new Set(selectedProductDeclination.imgs)]
          }
          setSelectedProductDeclination(selectedProductDeclination);
      }
  }, [selectedProductDeclination, reference]);

    const fetchData = (slug) => {
        if(origin && origin == "be"){
            origin = "fr"
        }
        let query = 'https://public-front-api.therunningcollective.fr/product?lang='+origin+'&shortURL='+slug
        axios
            .get(query)
            .then((res) => {
                const queryRef = qs.parse(location.search, {
                    ignoreQueryPrefix: true
                });

                let ref = queryRef.reference
                setProduct(res.data);
                setDeclination(ref ? res.data.declinations.filter(d => d.reference === ref)[0] : res.data.bestDeclinations)
                setSelectedProductDeclination(ref ? res.data.declinations.filter(d => d.reference === ref)[0] : (res.data.bestDeclinations.bestRetailer.retailerName !== "" ? res.data.bestDeclinations : res.data.declinations[0]))
                setParentCategory(res.data.headCategory.split("/products").pop())
                setGender(res.data.gender)
            })
            .catch((err) => {
                if(err && err.response && err.response.data && err.response.data.message == "error while getting product: product is not active"){
                    //window.location.href = "/404-not-found"
                }
            })
    };

    const fetchRating = (slug) => {
        let query = 'https://public-front-api.therunningcollective.fr/product-ratings?shortURL='+slug
        axios
            .get(query)
            .then((res) => {
                setProductRating(res.data)
                window.prerenderReady = true
            })
            .catch((err) => {
                window.prerenderReady = true
                console.log(err);
            })
    };

    const fetchHistoryPrice = (reference, beginDate, endDate, i18n) => {
        var lang = ""
        if (i18n.language == "be"){
            lang = "fr"
        } else {
            lang = i18n.language
        }

        let query = 'https://public-front-api.therunningcollective.fr/price-history?lang='+lang+'&shortURL='+reference + '&beginDate='+ formatDate(beginDate)+'&endDate='+formatDate(endDate)
        axios
            .get(query)
            .then((res) => {
                if(res && res.data && res.data.length > 0){
                    setHistoryPrices(res.data)
                } else {
                    setHistoryPrices(null)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const getHistoryPeriod = (historyPeriod) => {
        const d = new Date();

        switch (historyPeriod){
            case 'year':
                d.setFullYear(d.getFullYear()-1);
            case 'month':
                d.setMonth(d.getMonth() -1);
            case 'week':
                d.setDate(d.getDate() -7);
        }
        return d;
    }

    const fetchBlogData = (slug) => {
        let query = 'https://blog.therunningcollective.fr/wp-json/wp/v2/posts?_embed&slug='+slug
    
          axios
              .get(query)
              .then((res) => {
                  setArticle(res.data[0]);
                  window.prerenderReady = true
              })
              .catch((err) => {
                  console.log(err);
                  window.prerenderReady = true
                  //window.location.href = "/404-not-found"
              })
      };

      useEffect(() => {
        console.log(article)
      }, [article])

      const fetchProductLaunching = (slug) => {
        let query = 'https://public-front-api.therunningcollective.fr/product-launching?shortURL='+slug
    
          axios
              .get(query)
              .then((res) => {
                const queryRef = qs.parse(location.search, {
                    ignoreQueryPrefix: true
                });

                let ref = queryRef.reference
                setProductLaunching(res.data);
                setDeclinationLaunching(ref ? res.data.declinations.filter(d => d.reference === ref)[0] : null)
                window.prerenderReady = true
              })
              .catch((err) => {
                  console.log(err);
                  window.prerenderReady = true
              })
      };
    
      useEffect(() => {
        if (product && product.blogIntegratedShortURL && product.blogIntegratedShortURL !== ""){
            fetchBlogData(product.blogIntegratedShortURL)
        }
        if (product && product.availableSoon){
            fetchProductLaunching(product.shortURL)
        }
      }, [product])

    useEffect(() => {
        if(selectedProductDeclination && selectedProductDeclination.reference && historyPeriod){
            let start = getHistoryPeriod(historyPeriod);
            let end = new Date()
            fetchHistoryPrice(selectedProductDeclination.reference, start, end, i18n);
        }
    }, [reference, slug, selectedProductDeclination, historyPeriod, i18n]);

    useEffect(() => {
        fetchData(slug);
        fetchRating(slug);
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
    }, [slug]);

    useEffect(() => {
        if (product && selectedProductDeclination && parentCategory){
            ReactGA.send({ hitType: "pageview", page: location.pathname });
            GetUserData().then(data =>
            apiConversionRequest(data.query, "PageView", {value: selectedProductDeclination.bestRetailer.price > 0 ?
                    selectedProductDeclination.bestRetailer.price : selectedProductDeclination.price,
                productID: selectedProductDeclination.reference, productName: product.name})
            )
            let structured = {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": product.name,
                "image": [
                    selectedProductDeclination.firstImg,
                ],
                "description": product.description,
                "mpn": selectedProductDeclination.reference,
                "sku": selectedProductDeclination.reference,
                "gtin8": selectedProductDeclination.reference,
                "brand": {
                    "@type": "Brand",
                    "name": product.brand
                },
            }
            structured.additionalProperty = [
                {
                    "@type": "PropertyValue",
                    "name": "Types de produit",
                    "value": t(parentCategory) == "Pointes" ? "Pointes d'athlétisme" : t(parentCategory)
                },
                {
                    "@type": "PropertyValue",
                    "name": "Catégorie de produit",
                    "value": product.categories && product.categories.length > 0 ? product.categories[0] : null
                },
                {
                    "@type": "PropertyValue",
                    "name": "Sexe",
                    "value": t(product.gender)
                }
            ]
            if(selectedProductDeclination.bestRetailer.price){
                structured.offers = {
                    "@type": "AggregateOffer",
                    "offerCount": selectedProductDeclination.analysedRetailers.length,
                    "lowPrice": selectedProductDeclination.bestRetailer.price,
                    "highPrice": selectedProductDeclination.price,
                    "priceCurrency": "EUR",
                    "availability": product ? (product.active ? "InStock" : "OutOfStock") : "OutOfStock"
                }
            } else {
                structured.offers = {
                    "@type": "Offer",
                    "price": selectedProductDeclination.price,
                    "priceCurrency": "EUR",
                    "availability": "OutOfStock"
                }
            }

            if(productRating) {
                let ratingValue = 0

                const highestValue = productRating.reduce(function(prev, current) {
                    return (prev.stars > current.stars) ? prev : current
                })
                const worstValue = productRating.sort((a,b)=>a.stars-b.stars)[0].stars
                productRating.map(rating => ratingValue += rating.stars)

                structured.aggregateRating = {
                    "@type":"AggregateRating",
                    "ratingValue": Number((ratingValue / productRating.length).toFixed(1)),
                    "reviewCount": productRating.length,
                    "bestRating": highestValue.stars,
                    "worstRating": worstValue
                }

                structured.review =  []
                productRating.map(rating =>{
                    ratingValue += rating.stars
                    structured.review.push({
                        "@type":"Review",
                        "reviewRating":
                            {
                                "@type":"Rating",
                                "ratingValue": rating.stars
                            },
                        "author":
                            {
                                "@type":"Person",
                                "Name": rating.name
                            },
                        "name": "Review by " + rating.name + " on product : "+ product.name,
                        "reviewBody": rating.message,
                        "datePublished": new Date(rating.createdDate).toISOString(),
                    })
                })

            }
            let breadscrumb = {
                    "@type": "BreadcrumbList",
                    "@id": "https://app.therunningcollective."+origin+"/product/"+product.shortURL+"#breadscrumb",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "name": t('home'),
                            "item": "https://app.therunningcollective."+origin+"/"
                        },
                        {
                            "@type": "ListItem",
                            "position": 2,
                            "name": t(parentCategory),
                            "item": "https://app.therunningcollective."+origin+"/products/" + parentCategory
                        },
                        {
                            "@type": "ListItem",
                            "position": 3,
                            "name": product.name
                        }
                    ]
                }
            let structuredGlobal = {
                "@context": "https://schema.org",
                "@graph": [breadscrumb, structured]
            }
            setDataStructured(structuredGlobal)
        }

    }, [reference, product, selectedProductDeclination, productRating, parentCategory]);

  return (

    <Fragment>
        <MetaTags>
            <Helmet>
                <title>{product ? product.metaTitle + t('bestprice') : null}</title>
                <meta name="description" content={product ? product.metaDescription : null }/>
                <meta property="og:site_name" content={"The Running Collective | " + t('description')} />
                <meta http-equiv="Content-Language" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()}/>
                <meta property="og:type" content="product" />
                <meta property="og:local" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={product ? product.metaTitle + t('bestprice') : null} />
                <meta property="og:description" content={product ? product.metaDescription : null }/>
                <meta property="og:image" content={product ?  product.bestDeclinations.firstImg : null} />
                <meta property="product:productID" content={product ? product.bestDeclinations.reference : null }/>
                <meta property="product:brand" content={product ? product.brand : null}/>
                <meta property="product:availability" content={product ? (product.active ? "In stock" : "Out Of Stock") : "Out Of Stock"}/>
                <meta property="product:condition" content={product ? "New" : null}/>
                <meta property="product:pretax_price:amount" content={product ? (product.active ? product.bestDeclinations.bestRetailer.price / 1.2 : null) : null}/>
                <meta property="product:pretax_price:currency" content="EUR"/>
                <meta property="product:price:amount" content={product ? (product.active ? product.bestDeclinations.bestRetailer.price : null) : null}/>
                <meta property="product:price:currency" content="EUR"/>
                <meta name="distribution" content="global"/>
                <meta name="identifier-url" content={"https://app.therunningcollective."+origin}/>
                <meta name="copyright" content="The Running Collective"/>
                <meta name="robots" content="index, follow"/>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="keywords" content={product ? (product.active ? t(parentCategory) + " " + product.metaTitle + t('for') + getLabel(product.gender, t) + t('bestpricelow') : null) : null}/>
                {
                    dataStructured !== null ?
                        <script type="application/ld+json">
                            {JSON.stringify(dataStructured)}
                        </script>
                        :
                        null
                }
            </Helmet>
        </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>{t('home')}</BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL + "/products" + parentCategory}>{t(parentCategory)}</BreadcrumbsItem>
        {
            gender != "" && gender != "Unisex" ?
                <BreadcrumbsItem to={process.env.PUBLIC_URL + "/products" + parentCategory + "?gender="+ gender}>
                    {gender != "" ? t(gender.toLowerCase()) : null}
                </BreadcrumbsItem>
                : null
        }

        <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
          {product ? product.name : null} {product && selectedProductDeclination && product.headCategory == "/products/nutrition" && product.brand != "Maurten" ? " (" + selectedProductDeclination.color + ")" : null}
        </BreadcrumbsItem>
        {selectedProductDeclination ?
            <LayoutOne
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-2"
                headerTop="visible"
            location={location}>
                {/* breadcrumb */}
                <Breadcrumb />

                {/* product description with image */}
                {
                    product && product.availableSoon && declinationLaunching && declinationLaunching.retailers && declinationLaunching.retailers.length > 0?
                    <ProductImageDescription
                        spaceTopClass={isMobileOnly ? "" : "pt-30"}
                        spaceBottomClass="pb-20"
                        product={product}
                        currentDeclination={selectedProductDeclination}
                        setCurrentDeclination={setCurrentDeclination}
                        pathname={pathname}
                        galleryType="rightThumb"
                        othersSizesRef={othersSizesRef}
                        productRating={productRating}
                        setCurrentTab={setCurrentTab}
                        averageHistory={averageHistory}
                        setSelectedUserSize={setSelectedUserSize}
                        productLaunching={true}
                        productLaunchingRetailers={declinationLaunching && declinationLaunching.retailers ? declinationLaunching.retailers : null}
                    />
                    : 
                    <ProductImageDescription
                        spaceTopClass={isMobileOnly ? "" : "pt-30"}
                        spaceBottomClass="pb-20"
                        product={product}
                        currentDeclination={selectedProductDeclination}
                        setCurrentDeclination={setCurrentDeclination}
                        pathname={pathname}
                        galleryType="rightThumb"
                        othersSizesRef={othersSizesRef}
                        productRating={productRating}
                        setCurrentTab={setCurrentTab}
                        averageHistory={averageHistory}
                        setSelectedUserSize={setSelectedUserSize}
                    />
                }

                {/* product description tab */}
                {
                    product && product.availableSoon && declinationLaunching && declinationLaunching.retailers && declinationLaunching.retailers.length > 0?
                        null
                    : 
                    <ProductDescriptionTab
                        currentDeclination={selectedProductDeclination}
                        posts={product.posts}
                        product={product}
                        othersSizesRef={othersSizesRef}
                        productRating={productRating}
                        historyPrices={historyPrices}
                        setHistoryPeriod={setHistoryPeriod}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        setAverageHistory={setAverageHistory}
                        selectedUserSize={selectedUserSize}
                    />
                }

                {/* related product slider */}
                {
                    product.shopType && product.shopType.length > 0 ?
                        <RelatedApparelSlider
                            spaceBottomClass="pb-10"
                            headCategory={product.headCategory}
                            shopType={product.shopType[0] ? product.shopType[0] : ""}
                            brand={product.brand}
                            product={product}
                            origin={origin}
                        />  
                    : null
                }
                

                {/* related product slider */}
                {
                    product.categories && product.categories.length > 0 ?
                        <RelatedProductSlider
                            spaceBottomClass="pb-10"
                            headCategory={product.headCategory}
                            category={product.categories[0]}
                            product={product}
                            origin={origin}
                        />
                    : null
                }

                

                {
                    article &&  article._embedded && article._embedded['wp:featuredmedia'] && article._embedded['wp:featuredmedia']['0'] && article._embedded['wp:featuredmedia']['0'].source_url?
                    <div className="blog-area pt-100 pb-100">
                        <div className="container">
                            <div className="row flex-row-reverse">
                                <div className="col-lg-12">
                                    <div className="blog-details-wrapper">
                                        <div className="blog-details-top">
                                        <div className="blog-details-img">
                                            <img
                                                alt=""
                                                src={article._embedded['wp:featuredmedia']['0'].source_url}
                                            />
                                            </div>
                                            <div className="blog-details-content">
                                            <div className="blog-meta-2">
                                                <ul>
                                                <li>{(new Date(article.date)).toLocaleDateString()}</li>
                                                </ul>
                                            </div>
                                            <h3>{parse(article.title.rendered)}</h3>
                                            <span class="content-wordpress">
                                                {parse(article.content.rendered)}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
                


            </LayoutOne>
            :
            <div style={{textAlign: 'center'}}>
                <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                </Spinner>
            </div>
        }

    </Fragment>
  );
};

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

ProductTabRight.propTypes = {
  location: PropTypes.object,
};

export default multilanguage(ProductTabRight);