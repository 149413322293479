import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import axios from "axios";
import parse from "react-html-parser";
import moment from "moment";
import {Col, Row, Spinner} from "react-bootstrap";
import {
    BsCalendarMonth,
    BsClock,
    BsPinMap,
    BsStar,
    BsStarFill,
    CgWebsite,
    FaMountain, FaRunning,
    IoMdPricetags
} from "react-icons/all";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {BrowserView, isMobileOnly, isTablet} from 'react-device-detect';
import RelatedProductRaceSlider from "../../wrappers/product/RelatedProductRaceSlider";
import {OpenCapacitorSite} from "../../components/brand-logo/BrandLogoOneSingle";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {Favorite} from "@material-ui/icons";
import Snackbar from "@mui/material/Snackbar";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import {HeartBroken} from "@mui/icons-material";
import ReactPixel from "react-facebook-pixel";
import ShoesSizeUpdateModal from "../../components/header/ShoesSizeUpdateModal";
import PhysicalEventModal from "../../components/header/PhysicalEventModal";
import { getWithExpiry } from "../home/HomeFashionTwo";
import RewardModal from "../../components/header/RewardModal";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from '@mui/material/FormControl';
import {Divider, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import { GetAmbassadorAvailableSizes } from "../shop-product/tools";
import AmbassadorRewardModal from "../../components/header/AmbassadorRewardModal";

const AmbassadorReward = ({location}) => {
    const {pathname} = location;
    let slug = location.pathname.split("/ambassador-reward/").pop()
    const [reward, setEvent] = useState({})
    const [headerPadding, setHeaderPadding] = useState("15px")
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState('')
    const [openModalUpdateShoe, setOpenModalUpdateShoe] = useState(false)
    const [user, setUser] = useState(getWithExpiry('trc-front-user'));
    const [openModalAttendee, setOpenModalAttendee] = useState(false)
    const [loadingUser, setLoadingUser] = useState(false)
    const [openRewardValidModal, setOpenRewardValidModal] = useState(false);
    const [isAmbassador, setIsAmbassador] = useState(getWithExpiry("trc-front-is-ambassador"));
    const [selectedRewardGender, setSelectedRewardGender] = useState(null);
    const [selectedRewardBrand, setSelectedRewardBrand] = useState(null);
    const [selectedRewardSize, setSelectedRewardSize] = useState(null);
    const [availableBrands, setAvailableBrands] = useState(null);
    const [availableProducts, setAvailableProducts] = useState(null);
    const [availableProductsLoading, setAvailableProductsLoading] = useState(false);
    const [selectedRewardProduct, setSelectedRewardProduct] = useState(null);
    const [selectedRewardProductDeclination, setSelectedRewardProductDeclination] = useState(null);
    const [availableRewardProductDeclinations, setAvailableRewardProductDeclinations] = useState(null);
    const [availableRewardOutputProductDeclination, setAvailableRewardOutputProductDeclination] = useState(null);

    const fetchProductsData = (headCategory, gender, size, brand) => {
        setAvailableProductsLoading(true)
        let query = 'https://public-front-api.therunningcollective.fr/ambassador-reward-products?lang=fr&page=1&headCategory='+headCategory+'&gender='+gender+'&size='+size+'&brand='+brand
        axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        )
            .then((res) => {
                setAvailableProducts(res.data);
                setAvailableProductsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setAvailableProductsLoading(false);
            })
    };

    const fetchBrandsData = (parentCategory) => {
        let query = 'https://public-front-api.therunningcollective.fr/brands?headCategory='+parentCategory
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableBrands(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

    useEffect(() => {
        if(selectedRewardBrand && selectedRewardGender && reward && selectedRewardSize){
            fetchProductsData(reward.headCategory, selectedRewardGender, selectedRewardSize, selectedRewardBrand)
        }
    }, [selectedRewardBrand, selectedRewardGender, reward, selectedRewardSize]);

    useEffect(() => {
        if(selectedRewardProduct && availableProducts){
            availableProducts.map(product => {
                if (product.name == selectedRewardProduct){
                    setAvailableRewardProductDeclinations(product)
                }
            })
        }
    }, [selectedRewardProduct, availableProducts])


    useEffect(() => {
        if(selectedRewardProduct && selectedRewardProductDeclination && availableProducts){
            availableProducts.map(product => {
                if (product.name == selectedRewardProduct){
                    product.declinations.map(declination => {
                        if (declination.reference == selectedRewardProductDeclination){
                            setAvailableRewardOutputProductDeclination(declination)
                        }
                    }) 
                }
            })
        }
    }, [selectedRewardProduct, selectedRewardProductDeclination, availableProducts])

    
    const getProfile = () => {
        setLoadingUser(true)
        let query = 'https://public-front-api.therunningcollective.fr/profile'
        axios.get(query, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
                }
                ,
            }
        )
            .then((res) => {
                setUser(res.data)
            })
            .finally(() => {
                setLoadingUser(false)
            })
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
        GetUserData().then(
            data => apiConversionRequest(data.query, "PageView")
        )
        fetchBrandsData(reward.headCategory)
    }, [reward])

    useEffect(() => {
        if (reward) {
            var beginDay = new Date(reward.date).getDate()
            var endDateFormat = moment(reward.date);
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            setDate(beginDay + " " + endMonthStr + " " + endDateFormat.year())
        }
    }, [reward])

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        } else {
            setSmallScreen(true)
        }
    })

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
        fetchData(slug)
        getProfile()
    }, [slug])

    Array.prototype.contains = function (element) {
        return this.indexOf(element) > -1;
    };

    const handleValideReward = () => {
        setOpenRewardValidModal(true)
    }

    const fetchData = (slug) => {
        let query = 'https://public-front-api.therunningcollective.fr/ambassador-reward?id=' + slug
        axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,})
            .then((res) => {
                console.log(res.data)
                setEvent(res.data)
                setLoading(false);
                window.prerenderReady = true
            })
            .catch((err) => {
                //window.location.href = "/404-not-found"
                console.log(err);
            })

    };

    return (
        <Fragment>
            <MetaTags>
                <title>{reward ? reward.name : ""}</title>
                <meta
                name="description"
                content={reward ? reward.longDescription : ""}
                />
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/my-rewards"}>
                Mes récompenses
            </BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                {reward ? reward.name : ""}
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location} rewardParticipating={reward.isParticipating}/>
                <AmbassadorRewardModal
                    open={openRewardValidModal} setOpen={setOpenRewardValidModal} 
                    rewardGender={selectedRewardGender}
                    rewardBrand={selectedRewardBrand} 
                    rewardName={reward && reward.uniqueProduct ? reward.description : selectedRewardProduct} 
                    rewardSize={selectedRewardSize} 
                    rewardRef={selectedRewardProductDeclination}
                    rewardCover={reward && reward.uniqueProduct ? reward.cover :availableRewardOutputProductDeclination ? availableRewardOutputProductDeclination.firstImg : ""}
                    rewardPrice={reward && reward.uniqueProduct ? reward.price :availableRewardOutputProductDeclination ? (availableRewardOutputProductDeclination.price * 20).toFixed() : 0}
                    reward={reward}
                />

                <div className={isMobileOnly ? "" : "container"}>
                    {
                        reward && !loading ?
                            <div className="container-fluid" style={{paddingTop: headerPadding, paddingBottom: '40px'}}>
                                
                                <div style={{paddingTop: reward.name.length > 53 ? "40px" : reward.name.length > 25 ? "20px" : "10px"}}>
                                    <div style={{height: "200px", marginLeft: "-15px",
                                        marginRight: "-15px",
                                        marginTop: "-14px"}}>
                                        <div style={{height: "100%",
                                            border: reward.isParticipating ? "3px solid limegreen" : "none",
                                        }}>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    overflow: "hidden",
                                                    filter: "brightness(0.6)",
                                                    borderRadius: isMobileOnly ? "" : "10px"
                                                }}
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null; // prrewards looping
                                                    currentTarget.src = "https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"
                                                }}
                                                src={
                                                    reward && reward.cover && reward.cover != "" ? reward.cover
                                                        : "https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"}/>
                                        </div>
                                    </div>

                                    <div style={{paddingTop: "10px"}}>
                                        <h1 style={{fontSize:"18px", fontWeight: "600", margin:"0", lineHeight: "22px"}}>
                                            {reward && reward.name && reward.name != "" ? reward.name : null} / {reward && reward.description && reward.description != "" ? reward.description : null}
                                        </h1>
                                    </div>
                                    <hr style={{marginBottom: "10px", marginTop: "10px"}}/>
                                    <FormControl sx={{  width: "100%" }}>
                                        <InputLabel id="demo-multiple-name-label">Sexe :</InputLabel>
                                        <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={selectedRewardGender}
                                        onChange={(e) => setSelectedRewardGender(e.target.value)}
                                        input={<OutlinedInput label="Name" />}
                                        >
                                        {reward.genders.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name == "Men" ? "Homme" : "Femme"}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    {
                                        !reward.uniqueProduct ?   
                                            <FormControl sx={{  width: "100%" }} style={{marginTop: "20px"}}>
                                                <InputLabel id="demo-multiple-name-label">Marque :</InputLabel>
                                                <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                value={selectedRewardBrand}
                                                onChange={(e) => setSelectedRewardBrand(e.target.value)}
                                                input={<OutlinedInput label="Name" />}
                                                >
                                                {availableBrands && availableBrands.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl> 
                                        : null 
                                    }
                                    <FormControl sx={{  width: "100%" }} style={{marginTop: "20px"}}>
                                        <InputLabel id="demo-multiple-name-label">Taille :</InputLabel>
                                        <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={selectedRewardSize}
                                        onChange={(e) => setSelectedRewardSize(e.target.value)}
                                        input={<OutlinedInput label="Name" />}
                                        >
                                            { reward.headCategory && (selectedRewardBrand || reward.uniqueProduct) &&  GetAmbassadorAvailableSizes(reward.headCategory, selectedRewardBrand) ?
                                                GetAmbassadorAvailableSizes(reward.headCategory, selectedRewardBrand).map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>)) : null
                                            }
                                        </Select>
                                    </FormControl>
                                    {
                                        selectedRewardBrand && selectedRewardGender && selectedRewardSize && availableProducts  && !availableProductsLoading ?
                                        <FormControl sx={{  width: "100%" }} style={{marginTop: "20px"}}>
                                            <InputLabel id="demo-multiple-name-label">Liste des produits disponibles :</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                value={selectedRewardProduct}
                                                onChange={(e) => setSelectedRewardProduct(e.target.value)}
                                                input={<OutlinedInput label="Name" />}
                                            >
                                            {availableProducts && availableProducts.map((product) => (
                                                <MenuItem
                                                    key={product.shortURL}
                                                    value={product.name}
                                                >
                                                    {product.name}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>  : 
                                            selectedRewardBrand && selectedRewardGender && selectedRewardSize && !availableProducts && !availableProductsLoading ? 
                                            <div style={{textAlign: 'center', marginTop: "10px"}}>
                                                <div style={{textAlign: 'center'}}>
                                                    <span>Aucun produit disponible</span>
                                                </div>
                                            </div>
                                        : 
                                        selectedRewardBrand && selectedRewardGender && selectedRewardSize && availableProductsLoading ?
                                        <div style={{textAlign: 'center', marginTop: "10px"}}>
                                            <div style={{textAlign: 'center'}}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden"></span>
                                                </Spinner>
                                            </div>
                                        </div> : null }
                                        {
                                            availableRewardProductDeclinations ?
                                                <FormControl sx={{  width: "100%" }} style={{marginTop: "20px", marginBottom: "20px"}}>
                                                    <InputLabel id="demo-multiple-name-label">Liste des coloris disponibles :</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        value={selectedRewardProductDeclination}
                                                        onChange={(e) => setSelectedRewardProductDeclination(e.target.value)}
                                                        input={<OutlinedInput label="Name" />}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "top",
                                                                horizontal: "right"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "right"
                                                            },
                                                            sx: { mt: "-15px", ml: "5px"  }
                                                            }}
                                                    >
                                                    {availableRewardProductDeclinations && availableRewardProductDeclinations.declinations.map((product) => (
                                                        <MenuItem
                                                            key={product.reference}
                                                            value={product.reference}
                                                        >
                                                            <div><img src ={product.firstImg} style={{maxWidth: "100px"}}/>   - {product.reference}</div>
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl> 
                                        : null }
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        {
                                            reward.numberOfAttendes >= reward.winnersCount ? 
                                            <div>La récompense n'est plus disponible</div> : 
                                                user.countOfTokens < reward.price ? 
                                            <div>Tu n'as pas assez de TRCoins pour cette récompense</div>
                                        :
                                        isAmbassador && ((selectedRewardProductDeclination && availableRewardOutputProductDeclination) ||  (reward.uniqueProduct && selectedRewardSize))?
                                        <button
                                            onClick={() => handleValideReward()}
                                            style={{
                                                backgroundColor: "black",
                                                color: "white",
                                                borderRadius:'5px',
                                                padding: "10px 30px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                        }}>
                                            Echanger pour {(reward && reward.uniqueProduct ? reward.price : availableRewardOutputProductDeclination.price * 10).toFixed()}  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                                        </button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            : loading ?
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            : null
                    }
                </div>
            </LayoutOne>
        </Fragment>
    );
};

AmbassadorReward.propTypes = {
    location: PropTypes.object
};

export default AmbassadorReward;

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}