import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../assets/css/principal-slider-img.scss'
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";
import ProductDescriptionInfoLaunching from "../../components/product/ProductDescriptionInfoLaunching";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  currentDeclination,
  product,
  currency,
  cartItems,
  wishlistItems,
  compareItems,
  setCurrentDeclination,
  pathname,
  productRating,
  setCurrentTab,
  averageHistory,
  setSelectedUserSize,
  productLaunching,
  productLaunchingRetailers
}) => {

  const wishlistItem = wishlistItems.filter(
    wishlistItem => wishlistItem.id === currentDeclination.reference
  )[0];
  const compareItem = compareItems.filter(
    compareItem => compareItem.id === currentDeclination.reference
  )[0];

  const handleDragStart = (e) => e.preventDefault();
  const [carouselPrincipalItems, setCarouselPrincipalItems] = useState(null);
  const [carouselSlideInfo, setCarouselSlideInfo] = useState(null);
  const { t } = useTranslation()

  useEffect(() => {
    let items = []
    if (currentDeclination){
      let itemsImg = []

      if(currentDeclination.firstImg) {
        itemsImg.push(currentDeclination.firstImg)
      }
      if (currentDeclination.imgs && currentDeclination.imgs.length > 0) {
        currentDeclination.imgs.map(img => {
          itemsImg.push(img)
        })
      }
      let itemsDeduplicated =  itemsImg.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      })
      if (itemsDeduplicated.length > 0) {
        itemsDeduplicated.map((img, index) => {
          items.push( <img style={{width: isMobileOnly ? '80%' : '70%', marginTop: isMobileOnly ? "-20%" : "0"}} src={img} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix ! ("+index+")"}/>)
        })
      }
      setCarouselPrincipalItems(items)
    }
  }, [currentDeclination]);

  const { addToast } = useToasts();

  const finalProductPrice = currentDeclination.price;
  const discountedPrice = currentDeclination.bestRetailer.price;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = Math.round((currentDeclination.price - currentDeclination.bestRetailer.price) / currentDeclination.price * 100)

  useEffect(() => {
    if (discount > 1){
      setCarouselSlideInfo(discount)
    } else {
      setCarouselSlideInfo(null)
    }
  }, [discount]);

  useEffect(() => {
    if (currentDeclination.availableSoon && !currentDeclination.active) {
      setCarouselSlideInfo("Bientôt disponible")
    }
  }, [currentDeclination]);

  const renderSlideInfo = () => {
    if(carouselSlideInfo == "Bientôt disponible"){
      var sheet = document.createElement('style')
      sheet.innerHTML = ".product-image-description .alice-carousel__slide-info {background-color: red !important;}";
      document.body.appendChild(sheet);
      return t('availablesoon')
    } else {
      var sheet = document.createElement('style')
      sheet.innerHTML = ".product-image-description .alice-carousel__slide-info {background-color: black !important;}";
      document.body.appendChild(sheet);
      return `- ` + discount + `%`;
    }
  };

  return (
    <div
      className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-5 product-image-description" style={{paddingLeft: isMobileOnly ? "0px": "15px", paddingRight: isMobileOnly ? "0px": "15px"}}>
            {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                <AliceCarousel
                    mouseTracking
                    items={carouselPrincipalItems}
                    disableSlideInfo={carouselSlideInfo && ((carouselSlideInfo > 1 && carouselSlideInfo < 100) || carouselSlideInfo == "Bientôt disponible")  ? false : true}
                    disableButtonsControls={true}
                    renderSlideInfo={renderSlideInfo}
                    keyboardNavigation={true}/>
                : null}
          </div>
          <div className="col-lg-7 col-md-7">
            {/* product description info */}
            {
              productLaunching ?
              <ProductDescriptionInfoLaunching
                product={product}
                currentDeclination={currentDeclination}
                discountedPrice={discount}
                currency={currency}
                finalDiscountedPrice={finalDiscountedPrice}
                finalProductPrice={finalProductPrice}
                cartItems={cartItems}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
                addToast={addToast}
                setCurrentDeclination={setCurrentDeclination}
                pathname={pathname}
                productRating={productRating}
                setCurrentTab={setCurrentTab}
                averageHistory={averageHistory}
                setSelectedUserSize={setSelectedUserSize}
                productLaunchingRetailers={productLaunchingRetailers}
              />
              :
              <ProductDescriptionInfo
                product={product}
                currentDeclination={currentDeclination}
                discountedPrice={discount}
                currency={currency}
                finalDiscountedPrice={finalDiscountedPrice}
                finalProductPrice={finalProductPrice}
                cartItems={cartItems}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
                addToast={addToast}
                setCurrentDeclination={setCurrentDeclination}
                pathname={pathname}
                productRating={productRating}
                setCurrentTab={setCurrentTab}
                averageHistory={averageHistory}
                setSelectedUserSize={setSelectedUserSize}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  currentDeclination: PropTypes.object,
  pathname: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  othersSizesRef: PropTypes.any,
  productRating: PropTypes.array,
  setCurrentTab: PropTypes.func,
  averageHistory: PropTypes.any,
  setSelectedUserSize: PropTypes.func,
  productLaunching: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
