import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";
import NewsletterProductModal from "./NewsletterProductModal";
import {getUnavailableSizes} from "../../helpers/sizes";
import ReactGA from "react-ga4";
import {
    getMerchandDeliveryTimeFromName,
    getMerchandLogoFromName,
    getMerchandReturnTimeFromName
} from "../../wrappers/product/ProductDescriptionTab";
import NewsletterPriceModal from "./NewsletterPriceModal";
import {useHistory} from "react-router";
import {isBrowser, isMobileOnly, BrowserView, MobileView} from "react-device-detect";
import AliceCarousel from "react-alice-carousel";
import {getAvailablesBrandSizes, getSizeReference, getSizesStatus, getSizeStyle} from "../../pages/shop-product/tools";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {buildRedirectingLinkByUserID} from "../../helpers/redirecting-user";
import {useTranslation} from "react-i18next";
import {ReactComponent as ScissorsLogo} from '../../assets/img/scissors-svgrepo-com.svg';
import PimsterWidget from "../PimsterWidget";
import { TbBrandCarbon } from "react-icons/tb";
import GenerateAmbassadorLinkModal from "../header/GenerateAmbassadorLinkModal";
import { Divider } from "@mui/material";

export const redirection = (retailer, origin) => {
    console.log(retailer)
    let retailerURL = buildRedirectingLinkByUserID(retailer, origin)
    if (retailer){
        if (retailer.retailerName) {
            switch (retailer.retailerName) {
                case "KellerSports":
                    window.open(retailerURL, '_blank')
                case "Polar":
                    window.open(retailerURL, '_blank')
                case "sportShoes":
                    window.open(retailerURL, "_blank")
                default:
                    window.open('https://app.therunningcollective.'+origin+ "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
            }
        }
    } else {
        window.open('https://app.therunningcollective.'+origin+ + "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
    }
}

export const redirectionWithVoucher = (retailer, origin, voucher, retailerName) => {
    let retailerURL = buildRedirectingLinkByUserID(retailer, origin)
    if (retailer){
        if (retailer.retailerName) {
            switch (retailer.retailerName) {
                case "KellerSports":
                    window.open(retailerURL, '_blank')
                case "Polar":
                    window.open(retailerURL, '_blank')
                case "sportShoes":
                    window.open(retailerURL, "_blank")
                default:
                    window.open('https://app.therunningcollective.'+origin+'/redirection-with-voucher?voucher='+voucher+"&retailerName="+decodeURI(retailerName)+"&redirectionURL="+ decodeURI(retailerURL), '_blank')
            }
        }
    } else {
        window.open('https://app.therunningcollective.'+origin+'/redirection-with-voucher?voucher='+voucher+"&retailerName="+decodeURI(retailerName)+"&redirectionURL="+ decodeURI(retailerURL), '_blank')
    }
}

const ProductDescriptionInfoLaunching = ({
  product,
  currentDeclination,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  setCurrentDeclination,
  pathname,
  productRating,
  setCurrentTab,
  averageHistory,
  setSelectedUserSize,
  productLaunchingRetailers
}) => {
    const [selectedProductDeclination, setSelectedProductDeclination] = useState(
        currentDeclination ? currentDeclination : {}
    );

    const [modalShow, setModalShow] = useState(false);
    const [modalPriceShow, setModalPriceShow] = useState(false);
    const [ratingProductStars, setRatingProductStars] = useState(0);
    const { t } = useTranslation();

    const unavailableSizes = getUnavailableSizes(product, selectedProductDeclination);

    const [voucherInfo, setVoucherInfo] = useState(null)
    const [productSizes, setProductSizes] = useState([])
    const [avalaibleMerchands, setAvalaibleMerchands] = useState([]);
    const [adidasVoucher, setAdidasVoucher] = useState(null)
    const [showVoucher, setShowVoucher] = useState(false)
    const [carouselPrincipalItems, setCarouselPrincipalItems] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const handleDragStart = (e) => e.preventDefault();
    const { i18n } = useTranslation();
    const [origin, setOrigin] = useState("fr")
    const [isAmbassador, setIsAmbassador] = useState(false);
    const [openExportAmbassadorProduct, setOpenExportAmbassadorProduct] = useState(false);

    useEffect(() => {
      let token = getClientTokenData();
      if (token && token.isAmbassador){
        setIsAmbassador(true)
      }
    }, [])

    useEffect(() => {
        setOrigin(i18n.language)
    }, [])

    useEffect(() => {
        setProductSizes(getAvailablesBrandSizes(product.brand, product.headCategory))
        let items = []
        if (product.declinations){
            if (product.declinations.length > 0) {
                product.declinations.map((declination, index) => {
                    if (declination.reference !== currentDeclination.reference){
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(declination);
                                setCurrentDeclination(declination);
                                handleClick(pathname, declination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + declination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', borderRadius: "5px"}} src={declination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + declination.reference + " au meilleur prix ! ("+index+")"}/>
                            </div>
                        )
                    } else {
                        setActiveIndex(index)
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(currentDeclination);
                                setCurrentDeclination(currentDeclination);
                                handleClick(pathname, currentDeclination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + currentDeclination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', border: 'solid 1px', borderRadius: "5px"}} src={currentDeclination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix !"}/>
                            </div>
                        )
                    }
                })
            }
            setCarouselPrincipalItems(items)
        }
    }, [currentDeclination]);

    const handleClick = (pathname, reference) =>  window.history.replaceState(null, "", [pathname + "?reference=" + reference])

    const fetchMerchandsData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/merchants'

        axios
            .get(query)
            .then((res) => {
                setAvalaibleMerchands(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const generateVoucher = (voucherName) => {
        if (voucherName === "adidastogenerate" || voucherName === "adidastogenerateextra"){
            return adidasVoucher
        } else {
            return voucherName
        }
    }

    const redirectionOtherDeclination = (productSize, declinations, shortURL, productSizes, setCurrentDeclination) => {
        let declination = getSizeReference(declinations, productSizes, productSize)
        
        if (productSize.status == "othersDeclinations") {
            setCurrentDeclination(declination)
            window.history.replaceState(null, "", [pathname + "?reference=" + declination.reference])
        }
    }

    useEffect(() => {
        fetchMerchandsData();
        if(selectedProductDeclination.bestRetailer.retailerName === "adidas") {

        } else {
            setShowVoucher(true)
        }
    }, [selectedProductDeclination, showVoucher]);

    useEffect(() => {
        if(productRating && productRating.length > 0) {
            let stars = 0
            productRating.map(rating => {
                stars += rating.stars
            })
            stars = stars / productRating.length
            setRatingProductStars(stars)
        }
    }, [productRating]);

    const scroll = (tab) => {
        const section = document.querySelector( '#othersSizes' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab);
        return
    };

    const scrollDesktop = (tab) => {
        const section = document.querySelector( '#othersSizesDesktop' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab)
        return;
    };

    const activeDots = (product) => {
        if(product.declinations.length > 5 && isMobileOnly){
            return false
        } else if (product.declinations.length > 7 && !isMobileOnly) {
            return false
        }
        return true

    }

    const renderTooltipBestDeclination = (status) => (
        <Tooltip id="button-tooltip" >
            <div>{
                status == "available" ?
                    <span>{t('seeproductpartener')}</span>
                    :
                    status == "othersRetailers" ?
                        <span>{t('seeotherspartners')}</span> :
                        status == "othersDeclinations" ?
                            <span>{t('seeothersreference')}</span>
                        : <span>{t('nostocksize')}</span>
            }</div>
        </Tooltip>
    );

    const fetchVoucherInfo = (slug, reference, merchandName) => {
        let query = 'https://public-front-api.therunningcollective.fr/voucher-info?productName='+slug+'&reference='+reference+'&merchandName='+merchandName
        axios
            .get(query)
            .then((res) => {
                setVoucherInfo(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    /*useEffect(() => {
        if(selectedProductDeclination.bestRetailer.retailerName && product.shortURL && selectedProductDeclination.reference && origin == "fr"){
            fetchVoucherInfo(product.shortURL, selectedProductDeclination.reference, selectedProductDeclination.bestRetailer.retailerName)
        }
    }, [origin])*/

    return (
        <div className="product-details-content ml-70">
            <BrowserView>
                <h1>{product.name} {product.headCategory == "/products/nutrition" && product.brand != "Maurten" ? " (" + selectedProductDeclination.color + ")" : null}</h1>
            </BrowserView>

            {
                isMobileOnly ?
                    <div>
                        <div className="pro-details-size-color">
                            <div className="pro-details-color-wrap">
                                {product.declinations.length == 1 ?
                                    <span>{product.declinations.length} {t('availablecolor')} :</span>
                                    :
                                    <span>{product.declinations.length} {t('availablecolors')} :</span>
                                }
                                <div style={{cursor: 'pointer'}}>
                                    {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                        <AliceCarousel
                                            mouseTracking
                                            items={carouselPrincipalItems}
                                            disableButtonsControls={true}
                                            disableDotsControls={activeDots(product)}
                                            autoWidth={true}
                                            activeIndex={activeIndex}
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                        {
                            product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                                <>
                                    <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                                    </div>
                                    <div style={{marginTop: '10px', marginBottom: "10px"}}>
                                        <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                                    </div>
                                </>
                            : null
                        }
                        
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                        </div>
                    </div>
                    : null
            }
            {/*<div className="certificate">&nbsp;{product.trainingApproved ?
                <i title={"Produit approuvé pour les entrainements"} style={{color: 'green'}}
                   className="fas fa-running"></i>
                : null
            }
                {product.raceApproved ?
                    <i title={"Produit ayant réalisé un record du monde"} style={{color: 'gold'}}
                       className="fas fa-trophy"></i>
                    : null
                }</div>*/}

            <div style={{marginBottom: '10px', marginTop: '10px'}}></div>
            {
                product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                    <BrowserView>
                        <Row style={{marginTop: '10px', marginBottom: "20px"}}>
                            <Col xs={3}>
                                <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                            </Col>
                            <Col xs={9}>
                            {
                                product && product.headCategory == "/products/shoes" ?
                                    <>
                                        <div>
                                            <Row>
                                                <Col xs={isMobileOnly ? 6 : 4}>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                                    </div>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                                    </div>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>{t('reference')}</b> {selectedProductDeclination.reference}</span>
                                                    </div>
                                                </Col>
                                                <Col xs={isMobileOnly ? 6 : 4}>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                                    </div>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Divider style={{paddingBottom: "5px"}}/>
                                        </div>
                                    </>
                                : null
                            }
                            <Row>
                                <Col xs={12}>
                                    <div className="pro-details-size-color" style={{marginTop: "10px"}}>
                                        <div className="pro-details-color-wrap">
                                            {product.declinations.length == 1 ?
                                                <span>{product.declinations.length} {t('availablecolor')} :</span>
                                                :
                                                <span>{product.declinations.length} {t('availablecolors')} :</span>
                                            }
                                            <div style={{cursor: 'pointer'}}>
                                                {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                                    <AliceCarousel
                                                        mouseTracking
                                                        items={carouselPrincipalItems}
                                                        disableButtonsControls={true}
                                                        disableDotsControls={activeDots(product)}
                                                        autoWidth={true}
                                                        activeIndex={activeIndex}
                                                    />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>  
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    </BrowserView>
                : null
            }

            { isAmbassador ?
                <>
                    <div>
                        <Row>
                            <Col xs={12}>
                                <div className="pro-details-quality" style={{display: "block", margin: "0", marginTop: "10px"}}>
                                    <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                        <a
                                            href={null}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                            onClick={() => {
                                                setOpenExportAmbassadorProduct(!openExportAmbassadorProduct)
                                                ReactGA.event({
                                                    category: 'Product',
                                                    action: 'Export product ' + product.name
                                                });
                                            }}
                                        >
                                            Créer un lien ambassadeur
                                        </a>
                                    </div> 
                                </div>  
                            </Col>
                        </Row>
                    </div>
                    <div className="pro-details-list"></div>              
                </> : null
            }

            <BrowserView>
            {
                product && product.headCategory == "/products/shoes" && (
                    !product.pimsterProductName || product.pimsterProductName == "" || !product.pimsterModuleID  || product.pimsterModuleID == ""
                )?
                    <>
                        <div>
                            <Row>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>{t('reference')}</b> {selectedProductDeclination.reference}</span>
                                    </div>
                                </Col>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Divider style={{paddingBottom: "5px"}}/>
                        </div>
                        {
                            (product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "") ?
                            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div> : null
                        }
                    </>
                : null
            }   
            </BrowserView>


            <BrowserView>
                {
                    product && product.headCategory == "/products/shoes" && (
                        !product.pimsterProductName || product.pimsterProductName == "" || !product.pimsterModuleID  || product.pimsterModuleID == ""
                    ) ?
                        <>
                            <div className="pro-details-size-color" style={{marginTop: "10px"}}>
                                <div className="pro-details-color-wrap">
                                    {product.declinations.length == 1 ?
                                        <span>{product.declinations.length} {t('availablecolor')} :</span>
                                        :
                                        <span>{product.declinations.length} {t('availablecolors')} :</span>
                                    }
                                    <div style={{cursor: 'pointer', marginTop: "10px"}}>
                                        {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                            <AliceCarousel
                                                mouseTracking
                                                items={carouselPrincipalItems}
                                                disableButtonsControls={true}
                                                disableDotsControls={activeDots(product)}
                                                autoWidth={true}
                                                activeIndex={activeIndex}
                                            />
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                        </>
                    : null
                }
                <div className="product-anotherinfo-wrapper">
                    <Row>
                        <Col>
                            <div style={{fontSize: "17px"}}>🚨🚀 <b>Ce produit vient tout juste de sortir et est très demandé ! Nous avons référencé les revendeurs où il pourrait encore y avoir du stock disponible.</b></div>
                            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                        </Col>
                    </Row>
                    {productLaunchingRetailers && productLaunchingRetailers.length > 0 ? productLaunchingRetailers.map(retailer =>{
                        if (retailer) {
                            return(
                                <div key={retailer.retailerName}>
                                <Row key={retailer.retailerName} className="rowRetailer">
                                    <Col xs={6}>
                                        <img
                                            src={getMerchandLogoFromName(retailer.retailerName, avalaibleMerchands)}
                                            className="merchand-logo-url"
                                            alt=""
                                            style={{marginBottom: "10px"}}
                                        />
                                        <div>
                                            <b style={{fontSize: "18px"}}>Prix : </b>
                                            <span style={{color: "#4fa0a6", fontSize: "16px"}}>{currentDeclination.price}€</span>
                                        </div>
                                        <div>
                                            <b>Délais de livraisons :</b> &nbsp;
                                            {
                                            getMerchandDeliveryTimeFromName(retailer.retailerName, avalaibleMerchands, t, i18n)
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={6} style={{marginTop: "30px"}}>
                                        <div 
                                            onClick={() => {
                                                redirection(retailer, origin);
                                                ReactGA.event({
                                                    category: 'Product',
                                                    action: 'Redirect to ' + retailer.retailerName
                                                });
                                                ReactGA.event({
                                                    category: 'Product',
                                                    action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                });
                                                ReactPixel.track('AddToCart', {
                                                    content_name: product.name,
                                                    content_type: 'product',
                                                    content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                    content_ids: selectedProductDeclination.reference,
                                                    value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                    currency: 'EUR',
                                                })
                                            }}
                                            style={{
                                            cursor: "pointer",
                                            textTransform: "uppercase",
                                            padding: "10px", textAlign: "center", 
                                            fontSize: "14px",
                                            color: "white",
                                            border: "3px solid black", borderRadius: "10px", background: "#4fa0a6"}}>
                                            <a
                                                href={null}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                onClick={() => {
                                                    redirection(retailer, origin);
                                                    ReactGA.event({
                                                        category: 'Product',
                                                        action: 'Redirect to ' + retailer.retailerName
                                                    });
                                                    ReactGA.event({
                                                        category: 'Product',
                                                        action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                    });
                                                    ReactPixel.track('AddToCart', {
                                                        content_name: product.name,
                                                        content_type: 'product',
                                                        content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                        content_ids: selectedProductDeclination.reference,
                                                        value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                        currency: 'EUR',
                                                    })
                                                }}
                                            >
                                                Voir l'offre
                                            </a>
                                        </div>
                                        
                                    </Col>
                                </Row>
                                <hr/>
                                </div>
                            )
                        } else {
                            return (<span>{t('nosize')}</span>)
                        }
                        }) :
                        <span>{t('nosize')}</span>
                    }
                </div>
            </BrowserView>

            <MobileView>
            {
                product && product.headCategory == "/products/shoes" ?
                    <>
                        <div>
                            <Row>
                                <Col xs={isMobileOnly ? 6 : 3}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>{t('reference')}</b> {selectedProductDeclination.reference}</span>
                                    </div>
                                </Col>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                            <div className="product-anotherinfo-wrapper">
                                <Row>
                                    <Col>
                                        <div style={{fontSize: "16px"}}>🚨🚀 <b>Ce produit vient tout juste de sortir et est très demandé ! Nous avons référencé les revendeurs où il pourrait encore y avoir du stock disponible.</b></div>
                                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                                    </Col>
                                </Row>
                                {productLaunchingRetailers && productLaunchingRetailers.length > 0 ? productLaunchingRetailers.map(retailer =>{
                                    if (retailer) {
                                        return(
                                            <div key={retailer.retailerName}>
                                            <Row key={retailer.retailerName} className="rowRetailer">
                                                <Col xs={6}>
                                                    <img
                                                        src={getMerchandLogoFromName(retailer.retailerName, avalaibleMerchands)}
                                                        className="merchand-logo-url"
                                                        alt=""
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <div>
                                                        <b style={{fontSize: "18px"}}>Prix : </b>
                                                        <span style={{color: "#4fa0a6", fontSize: "16px"}}>{currentDeclination.price}€</span>
                                                    </div>
                                                    <div>
                                                        <b>Délais de livraisons :</b> &nbsp;
                                                        {
                                                        getMerchandDeliveryTimeFromName(retailer.retailerName, avalaibleMerchands, t, i18n)
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs={6} style={{marginTop: "30px"}}>
                                                    <div 
                                                        onClick={() => {
                                                            redirection(retailer, origin);
                                                            ReactGA.event({
                                                                category: 'Product',
                                                                action: 'Redirect to ' + retailer.retailerName
                                                            });
                                                            ReactGA.event({
                                                                category: 'Product',
                                                                action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                            });
                                                            ReactPixel.track('AddToCart', {
                                                                content_name: product.name,
                                                                content_type: 'product',
                                                                content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                content_ids: selectedProductDeclination.reference,
                                                                value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                                currency: 'EUR',
                                                            })
                                                        }}
                                                        style={{
                                                        cursor: "pointer",
                                                        textTransform: "uppercase",
                                                        padding: "10px", textAlign: "center", 
                                                        fontSize: "14px",
                                                        color: "white",
                                                        border: "3px solid black", borderRadius: "10px", background: "#4fa0a6"}}>
                                                        <a
                                                            href={null}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                            onClick={() => {
                                                                redirection(retailer, origin);
                                                                ReactGA.event({
                                                                    category: 'Product',
                                                                    action: 'Redirect to ' + retailer.retailerName
                                                                });
                                                                ReactGA.event({
                                                                    category: 'Product',
                                                                    action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                                });
                                                                ReactPixel.track('AddToCart', {
                                                                    content_name: product.name,
                                                                    content_type: 'product',
                                                                    content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                    content_ids: selectedProductDeclination.reference,
                                                                    value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                                    currency: 'EUR',
                                                                })
                                                            }}
                                                        >
                                                            Voir l'offre
                                                        </a>
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                            <hr/>
                                            </div>
                                        )
                                    } else {
                                        return (<span>{t('nosize')}</span>)
                                    }
                                    }) :
                                    <span>{t('nosize')}</span>
                                }
                            </div>
                    </>
                : null
            }   
            </MobileView>

            <div className="pro-details-meta" style={{display: "none"}}>
                <span>{t('lastupdate')} {product.updatedDate}</span>
            </div>
            <div id="othersSizesDesktop"></div>


            {/* product modal */}
            <NewsletterProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {/* product modal */}
            <NewsletterPriceModal
                show={modalPriceShow}
                onHide={() => setModalPriceShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {
                isAmbassador && openExportAmbassadorProduct ?
                    <GenerateAmbassadorLinkModal open={openExportAmbassadorProduct} setOpen={setOpenExportAmbassadorProduct} currentLink={window.location.href}/>
                : null
            }
        </div>
    );
};

ProductDescriptionInfoLaunching.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  currentDeclination: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
  setCurrentDeclination: PropTypes.func,
    othersSizesRef: PropTypes.any,
    productRating: PropTypes.array,
    setCurrentTab: PropTypes.func,
    averageHistory: PropTypes.any,
    setSelectedUserSize: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

function getHexaColorFromName(colorName, colors){
  if (colors && colors.length > 0) {
    let hexaColor = colors.filter(color => { return color.name === colorName})[0].hexaColor
    return rgbToHex(hexaColor.r, hexaColor.g, hexaColor.b)
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export function setCookie(cName, cValue, expHours) {
    let date = new Date();
    date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

export default connect(null, mapDispatchToProps)(ProductDescriptionInfoLaunching);
